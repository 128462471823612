// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// const apiUrl = 'http://localhost:8085';
const apiUrl = 'https://api.dev.gocno.io';
const notificationUrl = '';
const clientID = '';
const UI_URL = 'dev.gocno.io';

export let environment = {
  // Your web app's Firebase configuration
  allowedOrganizations: ['default'],
  firebaseConfig: {
    apiKey: 'AIzaSyCBVa8FDxEXA8TpTXIPzWOBI8SI1K7z6xU',
    authDomain: 'cnosaas1.firebaseapp.com',
    projectId: 'cnosaas1',
    storageBucket: 'cnosaas1.appspot.com',
    messagingSenderId: '242488620433',
    appId: '1:242488620433:web:6f59f755b985cbf24a1630',
    measurementId: 'G-DC4NQ3YVTK',
  },
  apimRedirect: "https://dev.apim.cloudoor.com",
  production: false,
  local: false,
  apiBaseURL: apiUrl,
  // socketURL : notificationUrl,
  clientID,
  baseUrl: UI_URL,
  githubClientID: '20ee1c0ff13414dd4e71',
  githubClientSecret: '33e380939da34102ff17a27d5c5a28d8a5cf90fa',
  gitlabClientID:
    'ff67a0299856863fc5323b6c5673fabffa99f78559d9f9824e77ce08fbff52b9',
  gitlabClientSecret:
    'gloas-ac0bd56d15fc80cad09cfd2bb4fd17ca901807d79bc8d089dfa715f6b67cfca1',
  bitbucketClientID: 'dgABkn8yZaJ5WVhCzw',
  bitbucketClientSecret: 'jQ45wxKRRM9MWWGwKKjdpmj7c6MYxnhf',
  gitProviderRedirectURL: 'https://' + UI_URL,
  schemas: 'https',
  // hotJarId: "3763750"
};
